<template>
  <b-modal v-model="modal" size="lg" hide-footer>
    <template #modal-title> Agregar crupier </template>
    <div class="mb-3 mx-auto">
      <multiselect
        v-model="value"
        label="name"
        track-by="name"
        :options="crupiers"
      ></multiselect>
    </div>
    <div class="mb-3 mx-auto">
      <div class="d-flex justify-content-center">
        <vs-button type="submit" @click="save">{{ $t('form.save') }}</vs-button>
        <vs-button @click="closeModa()" success type="button">{{
          $t('form.abort')
        }}</vs-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: { Multiselect },
  props: {
    crupiers: {
      type: Array,
      default: () => [],
    },
    gameUuid: {
      type: String,
      default: () => '',
    },
  },
  data() {
    return {
      modal: false,
      value: '',
    };
  },
  computed: {
    ...mapGetters({
      success: 'blackJack/getSuccess',
    }),
  },
  methods: {
    async save() {
      try {
        await this.add({ gameUuid: this.gameUuid, crupier: this.value.uuid });
        if (!this.success)
          return this.$swal.fire(
            'Error agregando crupier al juego',
            '',
            'error'
          );
        this.$swal.fire('Crupier agregado al juego', '', 'success');
        this.closeModa();
      } catch (error) {
        console.log('ERROR ADDING CRUPIER', error);
      }
    },
    closeModa() {
      this.modal = false;
      this.$emit('closeModal');
    },
    ...mapActions({
      add: 'blackJack/addCrupierToGame',
    }),
  },
};
</script>
