<template>
  <b-modal v-model="modal" size="lg" hide-footer @hide="handleHide">
    <template #modal-title> Enviar cartas </template>
    <div v-if="Object.keys(game).length">
      <h5>Nombre: {{ game.name }}</h5>
      <div class="emulator-container">
        <div class="cards-container">
          <b-card
            v-for="(item, i) in cards"
            :key="i"
            :style="`color: ${item.color}`"
            class="text-center"
            @click="selectCard(item)"
          >
            <p>
              {{ types[item.type] }}
            </p>
            <p>
              {{ item.name }}
            </p>
          </b-card>
        </div>
        <div v-if="card">
          <div>
            <b-card
              :style="`color: ${card.color}`"
              class="text-center card-selected"
            >
              <p>
                {{ types[card.type] }}
              </p>
              <p>
                {{ card.name }}
              </p>
            </b-card>
          </div>
          <div>
            <vs-button success @click="sendCard">ENVIAR</vs-button>
            <vs-button @click="card = null">CANCELAR</vs-button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  props: {
    game: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters({
      cards: 'blackJack/getCards',
      types: 'blackJack/getTypes',
      success: 'blackJack/getSuccess',
    }),
  },
  data() {
    return {
      modal: false,
      // cards: [],
      card: null,
    };
  },
  methods: {
    handleHide(e) {
      if (e.trigger === 'backdrop') return e.preventDefault();
    },
    selectCard(card) {
      this.card = card;
    },
    async sendCard() {
      await this.sendCardCrupier({ card: this.card, game: this.game });

      if (!this.success)
        return this.$swal.fire({
          title: 'Error enviando cartas',
          icon: 'error',
          timer: 1000,
        });

      this.$swal.fire({
        title: 'Carta enviada',
        icon: 'success',
        timer: 1000,
      });
    },
    ...mapActions({
      sendCardCrupier: 'blackJack/sendCardCrupier',
    }),
  },
};
</script>

<style lang="scss" scoped>
.emulator-container {
  display: flex;
  text-wrap: nowrap;
  justify-content: center;
}

.cards-container {
  display: grid;
  grid-template-columns: repeat(9, 1fr);
  gap: 5px;
  font-size: 1.5rem;
  max-height: 70vh;
  overflow-y: scroll;
}

.card-selected {
  font-size: 1.5rem;
  max-width: max-content;
  background: #ffffff;
  box-shadow: 0 0 20px 10px #b7b7b7;
  margin: 0 auto 1rem auto;
}
</style>
